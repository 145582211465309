<template>
  <router-view />
</template>

<style>
#app {
  font-family: "Rubik";
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
</style>
